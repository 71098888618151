import { useState, useContext } from "react";
import {
  Box,
  Container,
  Grid,
  RadioGroup,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Card,
  CardContent,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import Page from "components/Common/Page";
import Dropzone from "components/Minting/Dropzone";
import Deploy from "components/Minting/Deploy";
import RadioRowSelection from "components/Minting/RadioRowSelection";
import InputSelection from "components/Minting/InputSelection";
import selection from "components/Minting/selection";
import { tokenSelection } from "components/Minting/selection";
import useInput from "hooks/useInput";
import GlobalContext from "context/GlobalContext";

const useStyles = makeStyles((theme) => ({
  page: {},
  card: {
    width: "100%",
    margin: theme.spacing(1),
    maxWidth: 500,
    overflow: "visible",
  },
  title: {
    fontWeight: "bold",
    fontSize: 18,
  },
  contentAlign: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  root: {
    height: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    backgroundColor: theme.palette.background.main,
    zIndex: 2,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  stepper: {
    minWidth: 320,
    backgroundColor: theme.palette.background.main,
  },
  inner: {
    padding: 0,
    overflow: "auto",
    height: "100%",
    zIndex: 2,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center;",
  },
  inputWrapper: {
    width: "90%",
    margin: theme.spacing(1),
  },
  radioGroup: {
    width: "90%",
    display: "flex",
    flexDirection: "row",
  },
  container: {
    overflow: "auto",
  },
}));

const Root = ({ history }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { network } = useContext(GlobalContext);
  const [activeStep, setActiveStep] = useState(0);
  const [backdrop, setBackdrop] = useState(null);
  const [single, setSingle] = useState(null);
  const { t } = useTranslation();
  const nameInput = useInput("");
  const tickerInput = useInput("");
  const totalSupplyInput = useInput("");
  const priceInput = useInput("");
  const decimalsInput = useInput("18");
  const [option, setOption] = useState({
    coin: "bit",
    type: null,
    feature: {
      deploy: true,
      mint: false,
      burn: false,
      lockup: false,
      nft: false,
    },
  });
  /** */
  const [hash, setHash] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const inputSelection = {
    erc20Label: [
      {
        label: t("토큰 이름 (e.g. BitCoin)"),
        type: "name",
        input: nameInput,
      },
      {
        label: t("토큰 심볼 (e.g. BTC)"),
        type: "ticker",
        input: tickerInput,
      },
      {
        label: t("발행량"),
        type: "totalSupply",
        input: totalSupplyInput,
      },
      {
        label: t("소수점 단위") + " (2~18)",
        type: "decimals",
        input: decimalsInput,
      },
    ],

    erc721Label: [
      {
        label: t("NFT 토큰 이름"),
        type: "name",
        input: nameInput,
      },
      {
        label: t("NFT 토큰 판매가격") + ` (${option.coin.toUpperCase()})`,
        type: "price",
        input: priceInput,
      },
    ],
  };

  const successEnd = (txHash) => {
    setHash(txHash);
    enqueueSnackbar(
      t(
        "컨트랙트 생성이 완료되었습니다. 트랜잭션 승인이 완료되면 발행하신 토큰을 이용할 수 있습니다. 조금만 기다려주세요!"
      ),
      { variant: "success", autoHideDuration: 4000 }
    );
    history.push("/");
    setIsDisabled(true);
    setBackdrop(false);
    setIsEnd(true);
  };

  const pendingEnd = (txHash) => {
    setHash(txHash);
  };

  const handleNext = async () => {
    if (network !== process.env.REACT_APP_CHAIN_ID_HEX) {
      (async () => {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [
            {
              chainId: "0x" + Number(process.env.REACT_APP_CHAIN_ID).toString(16),
            },
          ],
        });
      })();

      enqueueSnackbar(t("메타마스크 네트워크 설정이 [ Bebit ] 가 아닙니다."), { variant: "error" });
      return;
    }

    if (activeStep === 0) {
      if (!option.type) {
        enqueueSnackbar(t("항목을 선택해주세요."), { variant: "error" });
        return;
      }
    }
    if (activeStep === 1) {
      switch (option.type) {
        case "erc-20":
          if (
            nameInput.value === "" ||
            tickerInput.value === "" ||
            totalSupplyInput.value === "" ||
            decimalsInput.value === ""
          ) {
            enqueueSnackbar(t("모든 항목을 올바르게 입력해주세요."), { variant: "error" });
            return;
          }

          if (isNaN(Number(totalSupplyInput.value)) || isNaN(Number(decimalsInput.value))) {
            enqueueSnackbar(t("모든 항목을 올바르게 입력해주세요. 발행량과 소수점 단위는 숫자여야합니다."), {
              variant: "error",
            });
            return;
          }

          if (Number(decimalsInput.value) < 2 || Number(decimalsInput.value) > 18) {
            enqueueSnackbar(t("모든 항목을 올바르게 입력해주세요. 소수점 단위는 2~18 사이어야 합니다."), {
              variant: "error",
            });
            return;
          }

          setActiveStep((prevActiveStep) => prevActiveStep + 1);

          break;
        case "erc-721":
          if (nameInput.value === "") {
            enqueueSnackbar(t("모든 항목을 올바르게 입력해주세요."), { variant: "error" });
            return;
          }
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          break;
        default:
          break;
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  const tokenRadioOnChange = ({ step, ticker }) => {
    switch (ticker) {
      case "erc-20":
        option["feature"].nft = false;
        break;
      case "erc-721":
        option["feature"].nft = true;
        break;
      default:
        option["feature"].nft = false;
        break;
    }

    option[step] = ticker;
    setOption({ ...option });
  };

  const checkOnChange = ({ step, ticker }) => {
    option[step][ticker] = !option[step][ticker];
    setOption({ ...option });
  };

  const getSteps = () => {
    return [t("생성할 코인 혹은 토큰을 선택합니다."), t("발행 정보를 입력합니다."), t("발행 정보를 확인합니다.")];
  };
  const steps = getSteps();

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <RadioGroup className={classes.radioGroup}>
            {tokenSelection[option.coin].map((item) => {
              return (
                <RadioRowSelection
                  key={item.ticker}
                  option={option}
                  handleChange={tokenRadioOnChange}
                  step="type"
                  {...item}
                />
              );
            })}
          </RadioGroup>
        );
      case 1:
        return (
          <Card className={classes.inputWrapper}>
            <CardContent>
              {option.type &&
                inputSelection[option.type.replace("-", "") + "Label"].map((item) => (
                  <InputSelection
                    key={item.label}
                    option={option}
                    setOption={setOption}
                    handleChange={checkOnChange}
                    step="feature"
                    {...item}
                  />
                ))}
              {option.type === "erc-721" && <Dropzone single={single} setSingle={setSingle} />}
            </CardContent>
          </Card>
        );
      case 2:
        return (
          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.title} component="h2">
                {t("발행 정보")}
              </Typography>
              {option.type &&
                selection[option.type.replace("-", "") + "Label"].map((item, index) => {
                  return (
                    <Box style={{ marginBottom: 7.5 }} key={index} className={classes.contentAlign}>
                      <Typography variant="body2" color="textSecondary">
                        {t(item.label)}
                      </Typography>
                      <Typography
                        style={{ wordBreak: "break-all", textAlign: "right", fontSize: 13 }}
                        variant="body2"
                        color="textPrimary"
                      >
                        {item.type === "name" && nameInput.value}
                        {item.type === "ticker" && tickerInput.value}
                        {item.type === "totalSupply" && totalSupplyInput.value}
                        {item.type === "decimals" && decimalsInput.value}
                        {item.type === "price" && priceInput.value}
                      </Typography>
                    </Box>
                  );
                })}
            </CardContent>
          </Card>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <Page className={classes.page} title="Minting">
      <Backdrop className={classes.backdrop} open={backdrop}>
        <CircularProgress size={20} color="inherit" />
      </Backdrop>
      <Container maxWidth="xs" className={classes.root}>
        <Stepper className={classes.stepper} activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <Box>
                  <Grid container className={classes.container}>
                    {getStepContent(index)}
                  </Grid>
                </Box>
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      sx={{ mr: 1 }}
                      size="large"
                      variant="outlined"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      color="primary"
                    >
                      Back
                    </Button>
                    {index === 2 ? (
                      <Deploy
                        location={{
                          state: {
                            ...option,
                            file: single,
                            input: {
                              name: nameInput.value,
                              ticker: tickerInput.value,
                              totalSupply: totalSupplyInput.value,
                              decimals: decimalsInput.value,
                              price: priceInput.value,
                            },
                          },
                        }}
                        setBackdrop={setBackdrop}
                        successEnd={successEnd}
                        pendingEnd={pendingEnd}
                      >
                        <Button size="large" variant="contained" color="primary" onClick={handleNext}>
                          {activeStep === steps.length - 1 ? "Finish" : "Next"}
                        </Button>
                      </Deploy>
                    ) : (
                      <Button size="large" variant="contained" color="primary" onClick={handleNext}>
                        {activeStep === steps.length - 1 ? "Finish" : "Next"}
                      </Button>
                    )}
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Container>
    </Page>
  );
};

export default Root;
