const primary = {
  50: "#E1E2E7",
  100: "#B3B6C3",
  200: "#81859B",
  300: "#4E5473",
  400: "#282F55",
  main: "#020a37", // contrast 3.83:1
  500: "#020A37",
  600: "#020931",
  700: "#01072A",
  800: "#010523",
  900: "#010316",
};

export default primary;
