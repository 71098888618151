import { Box, Typography, TextField } from "@mui/material";

const Selection = (item) => {
  return (
    <>
      <Box mb={0.5}>
        <TextField
          value={item.input.value}
          onChange={item.input.onChange}
          size="small"
          fullWidth
          label={item.label}
          margin="dense"
          required={true}
        />
      </Box>
      <Typography style={{ color: item.disabled && "lightgrey" }} variant="body2" color="textSecondary" component="p">
        {item.content}
      </Typography>
    </>
  );
};

export default Selection;
