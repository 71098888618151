import { RiHandCoinLine } from "react-icons/ri";
import { GiBurningDot } from "react-icons/gi";
import { AiFillLock } from "react-icons/ai";
import { GrDocumentStore } from "react-icons/gr";
import { BiCoinStack } from "react-icons/bi";
import { FaPhotoVideo } from "react-icons/fa";

export const tokenSelection = {
  bit: [
    {
      title: "ERC-20 토큰",
      ticker: "erc-20",
      icon: <BiCoinStack size={24} style={{ marginRight: 5 }} />,
      disabled: false,
    },
    {
      title: "NFT (ERC-721 토큰)",
      ticker: "erc-721",
      icon: <FaPhotoVideo size={24} style={{ marginRight: 5 }} />,
      disabled: false,
    },
  ],
};

export const erc20 = [
  {
    title: "표준 기능",
    ticker: "deploy",
    defaultChecked: true,
    readOnly: true,
    icon: <GrDocumentStore size={24} style={{ marginRight: 5 }} />,
  },
  {
    title: "토큰 추가 발행 기능",
    ticker: "mint",
    icon: <RiHandCoinLine size={24} style={{ marginRight: 5 }} />,
  },
  {
    title: "토큰 소각 기능",
    ticker: "burn",
    icon: <GiBurningDot size={24} style={{ marginRight: 5 }} />,
  },
  {
    title: "락업 기능",
    ticker: "lockup",
    icon: <AiFillLock size={24} style={{ marginRight: 5 }} />,
  },
];

export const erc20Label = [
  {
    label: "토큰 이름 (e.g. BitCoin)",
    type: "name",
  },
  {
    label: "토큰 심볼 (e.g. BTC)",
    type: "ticker",
  },
  {
    label: "발행량",
    type: "totalSupply",
  },
  {
    label: "소수점 단위",
    type: "decimals",
  },
];

export const erc721 = [
  {
    title: "NFT 생성",
    ticker: "nft",
    defaultChecked: true,
    readOnly: true,
    icon: <FaPhotoVideo size={24} style={{ marginRight: 5 }} />,
  },
];

export const erc721Label = [
  {
    label: "NFT 토큰 이름",
    type: "name",
  },
  {
    label: "NFT 판매 가격",
    type: "price",
  },
];

const selection = { erc20, erc20Label, erc721, erc721Label };

export default selection;
