import { Card, CardActionArea, CardContent, Radio, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  cardRow: {
    width: "100%",
    margin: theme.spacing(1),
    maxWidth: 500,
  },
  mediaLabel: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mediaWrapper: {
    padding: theme.spacing(3),
  },
  radio: {
    width: 20,
    height: 20,
  },
  media: {
    height: 100,
    backgroundSize: "contain",
  },
  icon: {
    size: 24,
    height: 24,
    marginRight: 5,
  },
}));

const OptionRow = (item) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Card className={classes.cardRow}>
      <CardActionArea
        disabled={item.disabled}
        onClick={item.handleChange.bind(null, { step: item.step, ticker: item.ticker })}
      >
        <CardContent style={{}}>
          <Box mb={0.5} className={classes.mediaLabel}>
            <Box display="flex" flexDirection="row" alignItems="center">
              {item.img && <img className={classes.icon} src={item.img} alt={item.title} />}
              {item.icon && item.icon}
              <Typography style={{ color: item.disabled && "lightgrey" }} component="h2">
                {t(item.title)}
              </Typography>
            </Box>
            <Radio
              disabled={item.disabled}
              checked={item.ticker === item.option[item.step]}
              value={item.ticker}
              className={classes.option}
            />
          </Box>
          <Typography
            style={{ color: item.disabled && "lightgrey" }}
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {item.content}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default OptionRow;
