import React from "react";
import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
// import AbstractShape from "components/Svg/abstract-shape.svg";
import AbstractShape from "resources/images/shape_ribbon.png";
import BannerImage from "resources/images/banner_open_image.png";
import LogoOrange from "resources/images/bebit_orange_logo.png";
import SearchBox from "components/Common/SearchBox";

const Ribbon = () => {
  const classes = useStyles();
  const lgView = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  // const searchOnSubmit = (e) => {
  //   e.preventDefault();
  //   history.push("/");
  // };

  const openBanner = () => {
    window.open("https://www.infinitymarket.net/");
  };

  return (
    <Box>
      <Box className={classes.ribbon}>
        <Container
          className={classes.root}
          maxWidth="xl"
          sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          <Box sx={{ width: lgView ? "100%" : "50%" }}>
            <Box p={1} mb={1}>
              <Typography className={classes.title} variant="h4">
                {process.env.REACT_APP_PROJECT_NAME.toUpperCase()} BLOCKCHAIN EXPLORER
              </Typography>
            </Box>
            <SearchBox />
            <Box display="flex" p={1} mb={1}>
              <Typography sx={{ fontSize: 13 }} className={classes.title}>
                Sponsored:
              </Typography>
              <img
                style={{ width: "auto", height: 24, marginLeft: 8, marginRight: 8 }}
                alt="orange_logo"
                src={LogoOrange}
              />
              <Typography sx={{ fontSize: 13 }} className={classes.title}>
                Everything about NFT. NFT mania!
              </Typography>
            </Box>
          </Box>
          {!lgView && (
            <Box sx={{ height: 150, minWidth: 500, width: "50%", display: "flex", justifyContent: "center" }}>
              <img onClick={openBanner} style={{ height: "100%" }} alt="banner_ad" src={BannerImage} />
            </Box>
          )}
        </Container>
      </Box>
      {lgView && (
        <Box sx={{ display: "flex", justifyContent: "center", py: 4, px: 3 }}>
          <img
            onClick={openBanner}
            style={{ height: "100%", maxWidth: 450, width: "100%" }}
            alt="banner_ad_under"
            src={BannerImage}
          />
        </Box>
      )}
    </Box>
  );
};
const useStyles = makeStyles((theme) => ({
  root: {},
  ribbon: {
    backgroundImage: `url(${AbstractShape})`,
    padding: theme.spacing(1),
    height: 270,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  title: {
    color: theme.palette.background.default,
  },
}));

export default Ribbon;
