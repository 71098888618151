import React from "react";
import GlobalContext from "context/GlobalContext";
import { BrowserRouter as Router } from "react-router-dom";
import Web3 from "web3";

import Routes from "Routes";
import moment from "moment";
import { SnackbarProvider } from "notistack";

moment.updateLocale("en", {
  relativeTime: {
    future: "0 seconds ago",
    past: "%s ago",
    s: "%d seconds",
    ss: "%d seconds",
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    w: "a week",
    ww: "%d weeks",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});

const App = () => {
  const [network, setNetwork] = React.useState(null);
  // const web3 = new Web3(new Web3.providers.WebsocketProvider("ws://15.164.92.178:25501"));
  const web3 = new Web3(new Web3.providers.HttpProvider(process.env.REACT_APP_HTTP_HOST));
  React.useEffect(() => {
    (async () => {
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
        await window.ethereum.enable();
        const chainId = await window.ethereum.request({ method: "eth_chainId" });
        setNetwork(chainId);
        window.ethereum.on("chainChanged", (chainId) => {
          setNetwork(chainId);
        });
      }
    })();
  }, []);

  const contextValue = { web3, network };
  return (
    <GlobalContext.Provider value={contextValue}>
      <SnackbarProvider autoHideDuration={1500} maxSnack={3}>
        <Router>
          <Routes />
        </Router>
      </SnackbarProvider>
    </GlobalContext.Provider>
  );
};

export default App;
