import React from "react";
import {
  Card,
  CardHeader,
  Divider,
  ListItem,
  ListItemText,
  ListItemAvatar,
  colors,
  Avatar,
  Typography,
  Box,
  Chip,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import moment from "moment";

import ListSkeleton from "components/Dashboard/ListSkeleton";
import GlobalContext from "context/GlobalContext";
import FromFactory from "components/Factory/FromFactory";
import BlockFactory from "components/Factory/BlockFactory";

const useStyles = makeStyles((theme) => ({
  textItemText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  hiddenText: {
    flex: 1,
    fontSize: 14,
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  primaryBox: {
    display: "flex",
    maxWidth: 171,
  },
  defaultText: {
    fontSize: 14,
  },
  avatarText: {},
  smallBox: {
    display: "flex",
    height: "fit-content",
    maxWidth: 260,
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  smallTopBox: {
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  minerText: {
    marginRight: theme.spacing(0.5),
  },
  card: {
    margin: theme.spacing(1),
  },
  listItemTextSm: {
    flex: `1 1 auto`,
    minWidth: 0,
    marginTop: 6,
    marginBottom: 6,
  },
}));

const HEIGHT_SM = 98;
const HEIGHT = 61;

const BlockList = ({ blocks, init }) => {
  const classes = useStyles();
  const { web3 } = React.useContext(GlobalContext);
  const smView = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Card className={classes.card}>
      <CardHeader title={t("최근 블록")} titleTypographyProps={{ fontSize: 13, fontWeight: "bold" }} />
      <Divider />
      <Box sx={{ overflow: "auto", maxHeight: smView ? HEIGHT_SM * 5 : HEIGHT * 5 }}>
        {blocks.map((item, index) => {
          return smView ? (
            <React.Fragment key={"block" + index}>
              <ListItem sx={{ py: 0.5 }}>
                {init ? (
                  <Box className={classes.listItemTextSm}>
                    <Box className={classes.smallTopBox}>
                      <BlockFactory row={item} />
                      <Typography sx={{ ml: 1, display: "inline" }} component="span" variant="caption">
                        {moment(Number(`${item.timestamp}000`)).fromNow()}
                      </Typography>
                    </Box>
                    <Box className={classes.smallBox}>
                      <Typography sx={{ mr: 0.5 }} variant="body2" color="text.primary">
                        {t("Miner")}
                      </Typography>
                      <FromFactory row={item} className={classes.hiddenText} />
                    </Box>
                    <Box sx={{ lineHeight: 1 }}>
                      <Chip
                        size="small"
                        label={`${web3.utils.fromWei(
                          item.gas_used.toString(),
                          "gwei"
                        )} ${process.env.REACT_APP_COIN_TICKER.toUpperCase()}`}
                      />
                    </Box>
                  </Box>
                ) : (
                  <ListSkeleton height={HEIGHT_SM} />
                )}
              </ListItem>
              <Divider />
            </React.Fragment>
          ) : (
            <React.Fragment key={"block" + index}>
              <ListItem sx={{ py: 0.5 }}>
                {init ? (
                  <React.Fragment>
                    <ListItemAvatar>
                      <Avatar
                        sx={{ border: "1px solid #E6E6E6", fontSize: 13, backgroundColor: "#F7F7F7", color: "#989999" }}
                        variant="rounded"
                        className={classes.avatarText}
                      >
                        Bk
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      className={classes.textItemText}
                      primary={
                        <Box className={classes.primaryBox}>
                          <BlockFactory className={classes.defaultText} row={item} />
                        </Box>
                      }
                      secondary={
                        <Typography minWidth={90} variant="caption">
                          {moment(Number(`${item.timestamp}000`)).fromNow()}
                        </Typography>
                      }
                    />
                    <ListItemText
                      className={classes.textItemText}
                      primary={
                        <Box>
                          <Box className={classes.primaryBox}>
                            <Typography sx={{ mr: 0.5 }} variant="body2" color="text.primary">
                              {t("Miner")}
                            </Typography>
                            <FromFactory row={item} className={classes.hiddenText} />
                          </Box>
                          <Box className={classes.primaryBox}>
                            <Typography style={{ fontSize: 14 }} component="span">
                              {item.txns + " txns"}
                            </Typography>
                          </Box>
                        </Box>
                      }
                    />
                    {/* <Chip label={`${web3.utils.fromWei(item.gas_used.toString())} ETH`} /> */}
                    <Chip
                      sx={{ minWidth: 75 }}
                      // label={`${numeral( item.gas_used).format(0, 0)} ${process.env.REACT_APP_COIN_TICKER.toUpperCase()}`}
                      label={`${web3.utils.fromWei(
                        item.gas_used.toString(),
                        "gwei"
                      )} ${process.env.REACT_APP_COIN_TICKER.toUpperCase()}`}
                    />
                  </React.Fragment>
                ) : (
                  <ListSkeleton height={HEIGHT} />
                )}
              </ListItem>
              <Divider />
            </React.Fragment>
          );
        })}
      </Box>
      {/* <CardActions>
        <Button color="primary" size="small" variant="contained" fullWidth>
          {t("모든 트랜잭션 보기")}
        </Button>
      </CardActions> */}
    </Card>
  );
};

export default BlockList;
