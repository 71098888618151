import React from "react";
import { Card, Container, Typography, Box } from "@mui/material";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

import Page from "components/Common/Page";
import FullPageLoader from "components/Common/FullPageLoader";
import FullPage404 from "components/Common/FullPage404";
import GlobalContext from "context/GlobalContext";
import Overview from "components/Block/Overview";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100% - 100px)",
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

const BlockDetail = ({ match }) => {
  const classes = useStyles();
  const { web3 } = React.useContext(GlobalContext);
  const [info, setInfo] = React.useState(false);
  const { t } = useTranslation();

  const getBlock = React.useCallback(async () => {
    const text = match.params.number;
    try {
      if (isNaN(Number(text))) {
        return setInfo(undefined);
      }

      if (Number(text) < 0) {
        return setInfo(undefined);
      }

      const body = { number: text };
      const {
        data: { data },
      } = await axios.post(process.env.REACT_APP_HOST + "/api/v1/block", body);

      if (!data) {
        return setInfo(undefined);
      }

      const uncleCount = await web3.eth.getBlockUncleCount(data.number);
      const detail = await web3.eth.getBlock(data.number);

      const info = {
        ...data,
        size: detail.size,
        transactions: detail.transactions,
        total_difficulty: detail.totalDifficulty,
        uncle_count: uncleCount,
      };

      setInfo(info);
    } catch (e) {
      console.log(e);
      return setInfo(undefined);
    }
  }, [match, web3.eth]);

  React.useEffect(() => {
    getBlock();
  }, [getBlock]);

  return (
    <Page title="Block" className={classes.root}>
      {info && (
        <Container className={classes.container} maxWidth={"lg"}>
          <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
            <Typography fontWeight="normal" variant="h4" mr={0.5}>
              {t("Block")} #{info.number}
            </Typography>
            <Typography variant="body2">{match.params.address}</Typography>
          </Box>
          <Card>
            <Overview info={info} />
          </Card>
        </Container>
      )}
      {info === undefined && <FullPage404 />}
      {info === false && <FullPageLoader />}
    </Page>
  );
};

export default BlockDetail;
