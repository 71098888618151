import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Dashboard from "pages/Dashboard";
import Block from "pages/Block/Detail";
import Transaction from "pages/Transaciton/Detail";
import TransactionList from "pages/Transaciton/List";
import Data from "pages/Data";
import Address from "pages/Address/Detail";
import Token from "pages/Token/Detail";
import Minting from "pages/Minting";

const GuestRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route exact path="/txs" component={TransactionList} />
      <Route exact path="/getdata" component={Data} />
      <Route exact path="/minting" component={Minting} />
      <Route exact path="/block/:number" component={Block} />
      <Route exact path="/tx/:hash" component={Transaction} />
      <Route exact path="/address/:address" component={Address} />
      <Route exact path="/token/:address" component={Token} />
      <Redirect from="*" to="/" />
    </Switch>
  );
};

const AppRouter = () => {
  return <GuestRoutes />;
};

export default AppRouter;
