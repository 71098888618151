import axios from "axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const Linking = ({ setBackdrop, children, location, successEnd = () => null, pendingEnd = () => null }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const onClick = async () => {
    setBackdrop(true);
    const publicAddress = await window.web3.eth.getCoinbase();
    const chainId = await window.ethereum.request({ method: "eth_chainId" });

    const body = new FormData();
    body.append("file", location.state.file);
    body.append("insertId", location.state.insertId);
    body.append("name", location.state.input.name);
    body.append("ticker", location.state.input.ticker);
    body.append("totalSupply", location.state.input.totalSupply);
    body.append("decimals", location.state.input.decimals);
    body.append("network", chainId);
    body.append("tokenType", location.state.type.toUpperCase());
    body.append("publicAddress", publicAddress);
    body.append("price", location.state.input.price);
    const { data } = await axios.post(`${process.env.REACT_APP_HOST}/contract`, body);
    try {
      if (data.status) {
        const weiGasPrice = window.web3.utils.toWei("1".toString(), "gwei");
        let hexGasPrice = window.web3.utils.toHex(weiGasPrice);
        const transactionParameters = {
          from: window.ethereum.selectedAddress, // must match user's active address.
          data: data.deploy,
          gas: window.web3.utils.toHex(data.limit),
          // gas: window.web3.utils.toHex(window.web3.utils.toWei(location.state.file ? "1" : "10000", "gwei")),
          gasPrice: hexGasPrice,
        };
        if (data.contract) {
          transactionParameters["to"] = data.contract;
        }
        const txHash = await window.ethereum.request({
          method: "eth_sendTransaction",
          params: [transactionParameters],
        });

        const { data: _data } = await axios.post(`${process.env.REACT_APP_HOST}/contract/finish`, {
          insertId: data.insertId,
          tx: txHash,
        });

        if (_data.status) {
          successEnd(txHash);
        } else {
          switch (_data.error) {
            case "permission_denied":
              enqueueSnackbar(t("권한이 없습니다."), { variant: "error" });
              pendingEnd(data.hash);
              break;
            default:
              enqueueSnackbar(t("서버 오류입니다. 잠시 후에 다시 시도해주세요."), { variant: "error" });
              break;
          }
        }
      } else {
        switch (data.error) {
          case "pending":
            enqueueSnackbar(t("결제 트랜잭션이 아직 승인되지 않았습니다. 잠시 후에 다시 시도해주세요."), {
              variant: "error",
            });
            pendingEnd(data.hash);
            break;
          case "invalid_id":
            enqueueSnackbar(t("거래 기록을 찾을 수 없습니다. 관리자에게 문의해주세요."), { variant: "error" });
            break;
          case "network":
            enqueueSnackbar(
              t(
                "결제한 기록과 다른 네트워크입니다. 메타마스크에서 결제한 네트워크와 동일하게 변경 후 다시 시도해주세요."
              ),
              { variant: "error" }
            );
            break;
          case "deploy":
            enqueueSnackbar(t("이미 계약이 배포된 기록입니다. 페이지를 새로고침 해주세요."), { variant: "error" });
            break;
          case "confirm":
            enqueueSnackbar(t("이미 계약이 배포된 기록입니다. 페이지를 새로고침 해주세요."), { variant: "error" });
            break;
          default:
            enqueueSnackbar(t("서버 오류입니다. 잠시 후에 다시 시도해주세요."), { variant: "error" });
            break;
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setBackdrop(false);
    }
  };

  return (
    <div onClick={onClick} style={{ display: "inline-block" }}>
      {children}
    </div>
  );
};

export default Linking;
