import React from "react";
import {
  Card,
  CardHeader,
  Container,
  Divider,
  Grid,
  Typography,
  Box,
  Avatar,
  ListItem,
  ListItemText,
  useMediaQuery,
  IconButton,
  Tab,
  Tabs,
  Paper,
  CircularProgress,
  Button,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import QRCode from "qrcode.react";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useTranslation } from "react-i18next";
import moment from "moment";
import numeral from "numeral";
import StringToColor from "string-to-color";
import qs from "qs";
import { useSnackbar } from "notistack";

import HashFactory from "components/Factory/HashFactory";
import ToFactory from "components/Factory/ToFactory";
import FromFactory from "components/Factory/FromFactory";
import Page from "components/Common/Page";
import Dialog from "components/Common/Dialog";
import FullPageLoader from "components/Common/FullPageLoader";
import FullPage404 from "components/Common/FullPage404";
import GlobalContext from "context/GlobalContext";
import abi from "resources/abi";
import CopyToClipboard from "react-copy-to-clipboard";
import { ContentCopyOutlined } from "@mui/icons-material";
import ReactExport from "react-export-excel";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100% - 100px)",
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  hiddenText: {
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const INITIAL_PAGE_LIMIT = 10;
// const TabPanel = (props) => {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {children}
//     </div>
//   );
// };

const tabTitles = ["Tx Logs", "Holders"];

const AddressDetail = ({ history, location, match }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { web3 } = React.useContext(GlobalContext);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isInvalid, setIsInvalid] = React.useState(false);
  const [info, setInfo] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [path, setPath] = React.useState(history.location);
  const initRef = React.useRef(false);
  const smView = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [tab, setTab] = React.useState(0);

  const [holders, setHolders] = React.useState(null);
  const [loadingCount, setLoadingCount] = React.useState(0);
  const [loadingCountMax, setLoadingCountMax] = React.useState(null);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const [rows, setRows] = React.useState(null);
  const [columns, setColumns] = React.useState(null);

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  const { t } = useTranslation();

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  // const columns = [{ field: "id", width: 115, headerName: t("번호") }];

  const onPageMove = React.useCallback(async () => {
    const text = match.params.address;
    const body = {
      item: qs.parse(location.search, { ignoreQueryPrefix: true }).a,
      address: text,
      init: false,
      page,
      perPage: INITIAL_PAGE_LIMIT,
    };
    const {
      data: { data },
    } = await axios.post(process.env.REACT_APP_HOST + "/api/v1/token/transaction/list", body);
    setInfo((info) => ({ ...info, data }));
  }, [match.params.address, page, location.search]);

  const getTx = React.useCallback(async () => {
    const text = match.params.address;
    try {
      const isHex = await web3.utils.isHex(text);
      if (!isHex) {
        initRef.current = undefined;
        return setIsInvalid(true);
      }

      const isHexStrict = await web3.utils.isHexStrict(text);

      if (!isHexStrict) {
        return history.push(`/address/0x` + text);
      }

      const isAddress = await web3.utils.isAddress(text);

      if (!isAddress) {
        initRef.current = undefined;
        return setIsInvalid(true);
      }
      const body = {
        item: qs.parse(location.search, { ignoreQueryPrefix: true }).a,
        address: text,
        init: !initRef.current,
        page,
        perPage: INITIAL_PAGE_LIMIT,
      };
      const {
        data: { data, count, contractType },
      } = await axios.post(process.env.REACT_APP_HOST + "/api/v1/token/transaction/list", body);
      const balance = web3.utils.fromWei(await web3.eth.getBalance(text));
      const transactionCount = await web3.eth.getTransactionCount(match.params.address);
      let contract = null;
      let type = "";
      const code = await web3.eth.getCode(text);
      if (code === "0x") {
        return setIsInvalid(true);
      } else {
        try {
          type = "token";
          if (contractType === "erc-1155") {
            contract = {
              type: contractType,
              name: "ERC-1155",
              ticker: "",
              totalSupply: "",
            };
          } else {
            const caller = new web3.eth.Contract(abi, text);
            const name = await caller.methods.name().call();
            const ticker = await caller.methods.symbol().call();
            const totalSupply = await caller.methods.totalSupply().call();

            const fromWeiSupply = web3.utils.fromWei(totalSupply);

            contract = {
              type: contractType,
              name,
              ticker,
              totalSupply: fromWeiSupply < 1 ? totalSupply : numeral(fromWeiSupply).format("0, 0.[0000]"),
            };
          }
        } catch {
          return setIsInvalid(true);
        } finally {
        }
      }

      const info = {
        data,
        transactionCount,
        balance,
        type,
        count,
        contract,
      };
      initRef.current = true;
      setInfo(info);
    } catch (e) {
      initRef.current = undefined;
      return setIsInvalid(true);
    }
  }, [history, match.params.address, page, web3, location.search]);

  React.useEffect(() => {
    if (path !== history.location) {
      setPath(history.location);
      return getTx();
    }

    if (initRef.current) {
      onPageMove();
    } else {
      getTx();
      setPath(history.location);
    }
  }, [onPageMove, getTx, history.location, path]);

  React.useEffect(() => {
    if (web3 && !holders) {
      (async () => {
        const body = { contractAddress: match.params.address };
        const { data } = await axios.post(process.env.REACT_APP_HOST + "/api/v1/token/holders", body);
        const contract = new web3.eth.Contract(abi, match.params.address);
        setLoadingCountMax(data.count);
        const balanceHolders = [];
        for (let i = 0; i < data.data.length; i++) {
          setLoadingCount(i);
          const balanceWei = await contract.methods.balanceOf(data.data[i].holder).call();
          if (data.data[i].type === "erc-20") {
            const balance = web3.utils.fromWei(balanceWei);
            balanceHolders.push({ ...data.data[i], balance });
          } else {
            balanceHolders.push({ ...data.data[i], balance: balanceWei });
          }
        }
        balanceHolders.sort((a, b) => {
          if (Number(a.balance) < Number(b.balance)) return 1;
          if (Number(a.balance) > Number(b.balance)) return -1;
          return 0;
        });
        const sortHolders = balanceHolders.map((item, index) => {
          return { ...item, id: index, column_index: index };
        });
        setHolders({ ...data, data: sortHolders });
        const keys = sortHolders.length > 0 ? Object.keys(sortHolders[0]) : [];

        const formatedRows = sortHolders.map((item) => {
          const values = {};
          keys.map((key) => {
            let value = null;
            value = item[key];
            return (values[key] = value);
          });

          return values;
        });
        setRows(formatedRows);
        const newColumns = keys.map((item) => {
          const columnsObject = {
            field: item,
            label: item,
          };
          return columnsObject;
        });
        setColumns(newColumns);
      })();
    }
  }, [match.params.address, web3]);

  const copyOnClick = () => {
    return enqueueSnackbar(t("클립보드에 복사되었습니다."), { variant: "success" });
  };

  return (
    <Page title={`Token`} className={classes.root}>
      <Dialog title={match.params.address} isOpen={isOpen} setIsOpen={setIsOpen}>
        <QRCode size={235} value={match.params.address} />
      </Dialog>
      {isInvalid && <FullPage404 />}
      {initRef.current === false && <FullPageLoader />}
      {initRef.current && (
        <Container className={classes.container} maxWidth={"lg"}>
          <Box display="flex" flexDirection="row" alignItems="center" mb={smView ? 1 : 2}>
            <Avatar
              sx={{
                fontSize: 14,
                width: 24,
                height: 24,
                mr: 0.5,
                backgroundColor: StringToColor(match.params.address),
              }}
            >
              {match.params.address.substring(2, 4).toUpperCase()}
            </Avatar>
            <Box display="flex" flexDirection="row" alignItems="center" mr={1}>
              <Typography fontWeight="normal" variant="h4" mr={0.5}>
                {info.type.toUpperCase()}
              </Typography>
              <Typography variant="body2">{info.contract.name}</Typography>
            </Box>
          </Box>
          <Grid spacing={2} container>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Card>
                <CardHeader title={t("개요")} titleTypographyProps={{ fontSize: 13, fontWeight: "bold" }} />
                {[
                  {
                    left: t("Token Tracker"),
                    right: `${info.contract.name} ${info.contract.ticker ? `(${info.contract.ticker})` : ""}`,
                  },
                  { left: t("Max Total Supply"), right: info.contract.totalSupply + " " + info.contract.ticker },
                ].map((item, index) => {
                  return (
                    <React.Fragment key={"overview" + index}>
                      <Divider />
                      <ListItem>
                        <ListItemText secondary={item.left}></ListItemText>
                        <ListItemText sx={{ textAlign: "right" }} secondary={item.right}></ListItemText>
                      </ListItem>
                    </React.Fragment>
                  );
                })}
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Card>
                <CardHeader title={t("추가 정보")} titleTypographyProps={{ fontSize: 13, fontWeight: "bold" }} />
                {[
                  { left: t("Contract"), right: match.params.address, copy: true },
                  { left: t("Type"), right: info.contract.type.toUpperCase(), copy: false },
                ].map((item, index) => {
                  return (
                    <React.Fragment key={"overview" + index}>
                      <Divider />
                      <ListItem>
                        <ListItemText secondary={item.left}></ListItemText>
                        <Box sx={{ width: smView ? 200 : "auto", display: "flex" }}>
                          <ListItemText
                            secondaryTypographyProps={{
                              textAlign: "right",
                              className: classes.hiddenText,
                            }}
                            secondary={item.right}
                          />
                          {item.copy && (
                            <CopyToClipboard onCopy={copyOnClick} text={item.right}>
                              <IconButton size="small" sx={{ ml: 0.25 }}>
                                <ContentCopyOutlined sx={{ fontSize: 12 }} />
                              </IconButton>
                            </CopyToClipboard>
                          )}
                        </Box>
                      </ListItem>
                    </React.Fragment>
                  );
                })}
              </Card>
            </Grid>

            <Grid sx={{ overflowX: "auto" }} item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Paper>
                <Tabs value={tab} onChange={(_, v) => setTab(v)} aria-label="basic tabs">
                  {tabTitles.map((title, index) => {
                    return <Tab key={index + "tab"} label={t(title)} {...a11yProps[index]} />;
                  })}
                </Tabs>
                {holders ? (
                  <React.Fragment>
                    <DataGrid
                      style={{ minHeight: 630, display: tab === 0 ? "none" : "block" }}
                      rowsPerPageOptions={[10]}
                      pagination
                      rowCount={holders.count}
                      pageSize={INITIAL_PAGE_LIMIT}
                      autoHeight
                      onPageChange={handlePageChange}
                      getRowId={(row) => row.column_index}
                      paginationMode="client"
                      columns={[
                        {
                          field: "index",
                          headerName: t("Index"),
                          flex: 2,
                          renderCell: ({ row }) => {
                            return row.column_index + 1;
                          },
                        },
                        {
                          field: "from",
                          headerName: t("From"),
                          flex: 6,
                          renderCell: ({ row }) => {
                            return <FromFactory className={classes.hiddenText} row={{ from: row.holder }} />;
                          },
                        },
                        {
                          field: "balance",
                          headerName: t("Quantity"),
                          flex: 4,
                          renderCell: ({ row }) => {
                            return numeral(row.balance).format("0,0.[0000]");
                          },
                        },
                      ]}
                      rows={holders.data}
                    />
                    <ExcelFile
                      filename={"Download_" + moment().format("YYYYMMDD")}
                      element={
                        <Button
                          onClick={() => {
                            setRows(null);
                            setColumns(null);
                          }}
                          variant="contained"
                          sx={{ mt: 1, width: "100%" }}
                        >
                          다운
                        </Button>
                      }
                    >
                      {rows && (
                        <ExcelSheet data={rows} name={"_"}>
                          {columns &&
                            columns.map((item, index) => {
                              return <ExcelColumn key={"columns" + index} label={item.label} value={item.field} />;
                            })}
                        </ExcelSheet>
                      )}
                    </ExcelFile>
                  </React.Fragment>
                ) : (
                  <Box
                    sx={{
                      display: tab === 0 ? "none" : "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                      minHeight: 630,
                    }}
                  >
                    <Box
                      sx={{
                        wisth: 100,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <CircularProgress />
                      <Typography>
                        {loadingCount} / {loadingCountMax}
                      </Typography>
                    </Box>
                  </Box>
                )}

                <DataGrid
                  style={{ minHeight: 630, display: tab === 0 ? "block" : "none" }}
                  rowsPerPageOptions={[10]}
                  pagination
                  rowCount={info.count}
                  pageSize={INITIAL_PAGE_LIMIT}
                  autoHeight
                  onPageChange={handlePageChange}
                  getRowId={(row) => row.column_index}
                  paginationMode="server"
                  columns={[
                    {
                      field: "hash",
                      headerName: t("Tx Hash"),
                      flex: 1,
                      renderCell: ({ row }) => {
                        return <HashFactory row={row} />;
                      },
                    },
                    // { field: "method", headerName: t("Method"), flex: 1 },
                    {
                      field: "from",
                      headerName: t("From"),
                      flex: 1,
                      renderCell: ({ row }) => {
                        return <FromFactory className={classes.hiddenText} row={row} />;
                      },
                    },
                    {
                      field: "to",
                      headerName: t("To"),
                      flex: 1,
                      renderCell: ({ row }) => {
                        return <ToFactory className={classes.hiddenText} row={row} />;
                      },
                    },
                    {
                      field: "value",
                      headerName: info.contract.type === "erc-20" ? t("Value") : t("TokenId"),
                      flex: 1,
                      renderCell: ({ row }) => {
                        if (row._type === "erc-20") {
                          return numeral(web3.utils.fromWei(row.value)).format("0,0");
                        } else if (row._type === "erc-721") {
                          return numeral(row.value).format("0,0");
                        } else if (row._type === "erc-1155") {
                          return numeral(row.value.split("^")[0]).format("0,0");
                        }
                      },
                    },
                    info.contract.type === "erc-1155" && {
                      field: "Value",
                      headerName: t("Value"),
                      flex: 1,
                      renderCell: ({ row }) => {
                        return row.value.split("^")[1];
                      },
                    },
                    {
                      field: "timestamp",
                      headerName: t("Timestamp"),
                      renderCell: ({ row }) => {
                        return moment(Number(`${row.timestamp}000`)).format("YYYY-MM-DD HH:mm:ss");
                      },
                      flex: 1,
                    },
                  ]}
                  rows={info.data}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
};

export default AddressDetail;
