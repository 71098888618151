import React from "react";
import { Box, Card, Grid, Avatar, CardContent, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import numeral from "numeral";
import { useTranslation } from "react-i18next";

import IconPrice from "resources/images/bebit_price_icon.png";
import IconMarketcap from "resources/images/bebit_marketcap_icon.png";
import IconTx from "resources/images/bebit_txcount_icon.png";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    // backgroundColor: theme.palette.background.default,
  },
  card: {
    width: "100%",
    height: "100%",
  },
  itemCard: {
    height: "100%",
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const Item = ({ title, content, icon, classes }) => {
  return (
    <Card className={classes.itemCard}>
      <CardContent style={{ paddingBottom: 16 }}>
        <Grid container alignItems="center">
          <Grid item>
            <Avatar sx={{ backgroundColor: "white" }} sizes="" className={classes.avatar}>
              {icon}
            </Avatar>
          </Grid>
          <Grid item ml={1.5}>
            <Typography color="textSecondary" variant="h6">
              {title}
            </Typography>
            <Typography display="inline" color="textPrimary" variant="h5">
              {content}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const ChainInfo = ({ chainInfo }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Grid container spacing={1}>
        {[
          {
            title: process.env.REACT_APP_COIN_NAME.toUpperCase() + " PRICE",
            content: "-",
            icon: <img src={IconPrice} alt="IconPrice" />,
          },
          { title: t("MARKET CAP"), content: "-", icon: <img src={IconMarketcap} alt="IconMarketcap" /> },
          {
            title: t("TRANSACTIONS"),
            content: numeral(chainInfo.txCount).format("0,0.[0000]"),
            icon: <img src={IconTx} alt="IconTx" />,
          },
        ].map((item, index) => {
          return (
            <Grid key={"ChainInfo" + index} item xs={12} sm={12} lg={4} xl={4}>
              <Item {...item} classes={classes} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default ChainInfo;
