import React from "react";
import { Container, IconButton, AppBar, Toolbar, Box, useMediaQuery, Button, Typography } from "@mui/material";

import { useHistory } from "react-router-dom";
import SearchBox from "components/Common/SearchBox";
import MetamaskIcon from "resources/images/metamask.png";
import MetamaskIconGrey from "resources/images/metamask_grey.png";
import ExplorerLogo from "resources/images/explorer_logo.png";
import GlobalContext from "context/GlobalContext";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

const Header = () => {
  const { network } = React.useContext(GlobalContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const smView = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const addNetwork = async () => {
    if (!window.ethereum) {
      return enqueueSnackbar(t("메타마스크를 설치해야합니다. 모바일 환경이라면 메타마스크 앱에서 접속해주세요."));
    }
    const chainId = await window.ethereum.request({ method: "eth_chainId" });

    if (chainId !== process.env.REACT_APP_CHAIN_ID_HEX) {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            // chainId: "0x" + Number(process.env.REACT_APP_CHAIN_ID).toString(16),
            chainId: "0x" + Number(process.env.REACT_APP_CHAIN_ID).toString(16),
            chainName: process.env.REACT_APP_COIN_NAME,
            nativeCurrency: {
              name: process.env.REACT_APP_COIN_NAME + " Mainnet",
              symbol: process.env.REACT_APP_COIN_TICKER.toUpperCase(), // 2-6 characters long
              decimals: 18,
            },
            // rpcUrls: [process.env.REACT_APP_HTTP_HOST],
            rpcUrls: [process.env.REACT_APP_HTTP_HOST],
            blockExplorerUrls: [process.env.REACT_APP_EXPLORER_HOST],
            iconUrls: [process.env.REACT_APP_SYMBOL_PATH],
          },
        ],
      });
    }
  };

  return (
    <AppBar enableColorOnDark sx={{ backgroundColor: "white" }} position="static">
      <Container maxWidth="xl">
        <Toolbar sx={{ justifyContent: "space-between" }} variant="dense">
          <IconButton
            onClick={() => history.push("/")}
            sx={{ p: 0 }}
            disableRipple={true}
            disableFocusRipple={true}
            aria-label="menu"
          >
            <img style={{ height: 37 }} src={ExplorerLogo} alt="explorer_logo" />
          </IconButton>
          <Box>
            <Button variant="outlined" onClick={() => history.push("/minting")}>
              {smView ? t("토큰 발행") : t("Bebit 기반 토큰 발행")}
            </Button>
            {network !== process.env.REACT_APP_CHAIN_ID_HEX && (
              <Button onClick={addNetwork}>
                {window.ethereum ? (
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <img alt="metamask" style={{ width: 24, height: 24 }} src={MetamaskIcon} />
                    <Typography sx={{ ml: 1, fontSize: 13, display: { xs: "none", sm: "none", md: "block" } }}>
                      Add {process.env.REACT_APP_COIN_NAME} Network
                    </Typography>
                  </Box>
                ) : (
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <img alt="metamask_gray" style={{ width: 24, height: 24 }} src={MetamaskIconGrey} />
                    <Typography sx={{ ml: 1, fontSize: 13, display: { xs: "none", sm: "none", md: "block" } }}>
                      NEED METAMASK
                    </Typography>
                  </Box>
                )}
              </Button>
            )}
          </Box>
        </Toolbar>
        {history.location.pathname !== "/" && (
          <Toolbar disableGutters sx={{ justifyContent: "flex-end" }} variant="dense">
            <Box sx={{ width: smView ? "100%" : "50%", display: "flex", justifyContent: "flex-end" }}>
              <SearchBox size="small" />
            </Box>
          </Toolbar>
        )}
      </Container>
    </AppBar>
  );
};

export default Header;
