import React from "react";
import {
  Card,
  CardHeader,
  Divider,
  ListItem,
  ListItemText,
  ListItemAvatar,
  colors,
  Avatar,
  Typography,
  Box,
  Chip,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import moment from "moment";
import numeral from "numeral";

import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import ListSkeleton from "components/Dashboard/ListSkeleton";
import GlobalContext from "context/GlobalContext";
import ToFactory from "components/Factory/ToFactory";
import FromFactory from "components/Factory/FromFactory";
import HashFactory from "components/Factory/HashFactory";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  textItemText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  hiddenText: {
    flex: 1,
    fontSize: 14,
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  primaryBox: {
    display: "flex",
    maxWidth: 171,
  },
  defaultText: {
    fontSize: 14,
  },
  avatarText: {
    backgroundColor: colors.grey,
    fontSize: 17,
  },
  smallBox: {
    display: "flex",
    maxWidth: 260,
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  smallTopBox: {
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  smallBottomBox: {
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    display: "flex",
  },
  minerText: {
    marginRight: theme.spacing(0.5),
  },
  card: {
    margin: theme.spacing(1),
  },
  listItemTextSm: {
    flex: `1 1 auto`,
    minWidth: 0,
    marginTop: 6,
    marginBottom: 6,
  },
}));

const HEIGHT_SM = 95;
const HEIGHT = 61;

const Transaction = ({ init, transactions }) => {
  const history = useHistory();
  const classes = useStyles();
  const { web3 } = React.useContext(GlobalContext);
  const smView = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  return (
    <Card className={classes.card}>
      <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <CardHeader title={t("최근 트랜잭션")} titleTypographyProps={{ fontSize: 13, fontWeight: "bold" }} />
        <IconButton
          onClick={() => {
            history.push("/txs");
          }}
          sx={{ mr: 1 }}
        >
          <ViewHeadlineIcon />
        </IconButton>
      </Box>
      <Divider />
      <Box sx={{ overflow: "auto", maxHeight: smView ? HEIGHT_SM * 5 : HEIGHT * 5 }}>
        {transactions.map((item, index) => {
          return smView ? (
            <React.Fragment key={"block" + index}>
              <ListItem sx={{ py: 0.5 }}>
                {init ? (
                  <Box className={classes.listItemTextSm}>
                    <Box className={classes.smallBox}>
                      <Typography sx={{ mr: 0.5, display: "inline" }} component="span" variant="body2">
                        {t("TX Hash")}
                      </Typography>
                      <HashFactory row={item} className={classes.hiddenText} />
                    </Box>
                    <Box className={classes.smallBottomBox}>
                      <Box className={classes.primaryBox}>
                        <Typography sx={{ mr: 0.5, lineHeight: 1.5 }} variant="body2" color="text.primary">
                          {t("From")}
                        </Typography>
                        <FromFactory row={item} className={classes.hiddenText} />
                      </Box>
                      <Box className={classes.primaryBox} sx={{ ml: 1 }}>
                        <Typography sx={{ mr: 0.5, lineHeight: 1.5 }} variant="body2" color="text.primary">
                          {t("To")}
                        </Typography>
                        <ToFactory row={item} className={classes.hiddenText} />
                        {/* <Link to="/" className={classes.hiddenText}>
                          {item.to}
                        </Link> */}
                      </Box>
                    </Box>
                    <Box sx={{ lineHeight: 1 }}>
                      <Chip
                        size="small"
                        label={`${numeral(web3.utils.fromWei(item.value)).format(
                          "0,0.[0000]"
                        )} ${process.env.REACT_APP_COIN_TICKER.toUpperCase()}`}
                      />
                    </Box>
                  </Box>
                ) : (
                  <ListSkeleton height={HEIGHT_SM} />
                )}
              </ListItem>
              <Divider />
            </React.Fragment>
          ) : (
            <React.Fragment key={"block" + index}>
              <ListItem sx={{ py: 0.5 }}>
                {init ? (
                  <>
                    <ListItemAvatar>
                      <Avatar
                        sx={{ border: "1px solid #E6E6E6", fontSize: 13, backgroundColor: "#F7F7F7", color: "#989999" }}
                        className={classes.avatarText}
                      >
                        Tx
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      className={classes.textItemText}
                      primary={
                        <Box className={classes.primaryBox} sx={{ maxWidth: 120 }}>
                          <HashFactory row={item} className={classes.hiddenText} />
                        </Box>
                      }
                      secondary={
                        <Typography variant="caption">{moment(Number(`${item.timestamp}000`)).fromNow()}</Typography>
                      }
                    />
                    <ListItemText
                      className={classes.textItemText}
                      primary={
                        <Box>
                          <Box className={classes.primaryBox}>
                            <Typography sx={{ mr: 0.5 }} variant="body2" color="text.primary">
                              {t("From")}
                            </Typography>
                            <FromFactory row={item} className={classes.hiddenText} />
                          </Box>
                          <Box className={classes.primaryBox}>
                            <Typography sx={{ mr: 0.5 }} variant="body2" color="text.primary">
                              {t("To")}
                            </Typography>
                            <ToFactory row={item} className={classes.hiddenText} />
                            {/* <Link to="/" className={classes.hiddenText}>
                              {item.to}
                            </Link> */}
                          </Box>
                        </Box>
                      }
                    />
                    <Chip
                      sx={{ minWidth: 75 }}
                      label={`${numeral(web3.utils.fromWei(item.value)).format(
                        "0,0.[0000]"
                      )} ${process.env.REACT_APP_COIN_TICKER.toUpperCase()}`}
                    />
                  </>
                ) : (
                  <ListSkeleton height={HEIGHT} />
                )}
              </ListItem>
              <Divider />
            </React.Fragment>
          );
        })}
      </Box>
      {/* <CardActions>
        <Button size="small" variant="contained" fullWidth>
          {t("모든 블록 보기")}
        </Button>
      </CardActions> */}
    </Card>
  );
};

export default Transaction;
