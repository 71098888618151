import React from "react";
import { Card, Container } from "@mui/material";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";

import HashFactory from "components/Factory/HashFactory";
import ToFactory from "components/Factory/ToFactory";
import FromFactory from "components/Factory/FromFactory";
import Page from "components/Common/Page";
import FullPageLoader from "components/Common/FullPageLoader";
import FullPage404 from "components/Common/FullPage404";
import GlobalContext from "context/GlobalContext";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100% - 100px)",
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  hiddenText: {
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  linkText: {
    fontSize: 14,
  },
}));

const INITIAL_PAGE_LIMIT = 10;

const TransactionList = ({ history, match }) => {
  const classes = useStyles();
  const { web3 } = React.useContext(GlobalContext);
  const [info, setInfo] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [isInvalid, setIsInvalid] = React.useState(false);

  const { t } = useTranslation();
  const initRef = React.useRef(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const onPageMove = React.useCallback(async () => {
    setIsLoading(true);
    const body = { address: null, init: false, page, perPage: INITIAL_PAGE_LIMIT };
    const {
      data: { data },
    } = await axios.post(process.env.REACT_APP_HOST + "/api/v1/transaction/list", body);

    setInfo((info) => ({ ...info, data }));
    setIsLoading(false);
  }, [page]);

  const getTx = React.useCallback(async () => {
    try {
      const body = { address: null, init: !initRef.current, page, perPage: INITIAL_PAGE_LIMIT };
      const {
        data: { data, count },
      } = await axios.post(process.env.REACT_APP_HOST + "/api/v1/transaction/list", body);
      const info = {
        data,
        count,
      };
      initRef.current = true;
      setInfo(info);
    } catch (e) {
      initRef.current = undefined;
      return setIsInvalid(true);
    }
  }, [page]);

  React.useEffect(() => {
    if (initRef.current) {
      onPageMove();
    } else {
      getTx();
    }
  }, [onPageMove, getTx]);

  return (
    <Page title="Transaction" className={classes.root}>
      {initRef.current && (
        <Container className={classes.container} maxWidth={"lg"}>
          <Card>
            <DataGrid
              loading={isLoading}
              rowsPerPageOptions={[10]}
              pagination
              rowCount={info.count}
              pageSize={INITIAL_PAGE_LIMIT}
              autoHeight
              onPageChange={handlePageChange}
              getRowId={(row) => row.column_index}
              paginationMode="server"
              columns={[
                {
                  field: "hash",
                  headerName: t("Tx Hash"),
                  flex: 1,
                  renderCell: ({ row }) => {
                    return <HashFactory row={row} />;
                  },
                },
                // { field: "method", headerName: t("Method"), flex: 1 },
                { field: "block_number", headerName: t("Block"), flex: 1 },
                {
                  field: "from",
                  headerName: t("From"),
                  flex: 1,
                  renderCell: ({ row }) => {
                    return <FromFactory className={classes.hiddenText} row={row} />;
                  },
                },
                {
                  field: "to",
                  headerName: t("To"),
                  flex: 1,
                  renderCell: ({ row }) => {
                    return <ToFactory className={classes.hiddenText} row={row} />;
                  },
                },
                {
                  field: "value",
                  headerName: t("Value"),
                  flex: 1,
                  renderCell: ({ row }) => {
                    return web3.utils.fromWei(row.value) + " " + process.env.REACT_APP_COIN_TICKER.toUpperCase();
                  },
                },
                {
                  field: "timestamp",
                  headerName: t("Timestamp"),
                  renderCell: ({ row }) => {
                    return moment(Number(`${row.timestamp}000`)).format("YYYY-MM-DD HH:mm:ss");
                  },
                  flex: 1,
                },
              ]}
              rows={info.data}
            />
          </Card>
        </Container>
      )}
      {isInvalid && <FullPage404 />}
      {initRef.current === false && <FullPageLoader />}
    </Page>
  );
};

export default TransactionList;
