import React from "react";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { Container, Typography, useMediaQuery } from "@mui/material";
import primary from "styles/theme/colors";
import AbstractShape from "resources/images/shape_footer.png";
import WhiteLogo from "resources/images/bebit_white_logo.png";

const useStyles = makeStyles((theme) => ({
  footerContent: {
    height: "calc(100% - 100px)",
  },
  footer: {
    backgroundImage: `url(${AbstractShape})`,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "white",
    lineHeight: 1.8,
    fontSize: "0.8rem",
    fontWeight: 400,
    minHeight: 140,
    background: `linear-gradient(140.79deg, ${primary["main"]} 2.12%, ${primary[800]} 96.36%)`,
  },
}));

const Footer = () => {
  const smView = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  return (
    <Box
      sx={smView ? { flexDirection: "column", justifyContent: "center", fontSize: "0.7rem", minHeight: 130 } : {}}
      className={classes.footer}
    >
      <Container
        sx={
          smView ? {} : { display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }
        }
      >
        <Box
          className={classes.footercontent}
          sx={smView ? { textAlign: "center" } : { textAlign: "left" }}
          textAlign="left"
        >
          <Box sx={{ display: "flex", alignItems: "center", mb: 1, justifyContent: smView ? "center" : "flex-start" }}>
            <img style={{ height: 22, marginRight: 5 }} src={WhiteLogo} alt="bebit_white_logo" />
            <Typography sx={{ fontSize: 15, textAlign: "center" }}>Powered By BEBIT</Typography>
          </Box>
          Two Harbourfront, unit 201, 2/F,
          <br />
          22 Tak Fung Street, Hung Hom, Hong Kong
          <br />
          info@rushcoin.io
        </Box>
        <Box
          className={classes.footercontent}
          sx={smView ? { textAlign: "center" } : { textAlign: "right" }}
          textAlign="right"
        >
          copyright © 2021 GamechainHK LIMITED. all rights reserved.
          <br />
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
