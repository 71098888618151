import React from "react";
import { Box, Button, Card, Container, Input, Typography, TextField } from "@mui/material";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterDateFns";

import HashFactory from "components/Factory/HashFactory";
import ToFactory from "components/Factory/ToFactory";
import FromFactory from "components/Factory/FromFactory";
import Page from "components/Common/Page";
import FullPageLoader from "components/Common/FullPageLoader";
import FullPage404 from "components/Common/FullPage404";
import GlobalContext from "context/GlobalContext";
import moment from "moment";
import useInput from "hooks/useInput";
import { useSnackbar } from "notistack";
import ReactExport from "react-export-excel";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100% - 100px)",
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  hiddenText: {
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  linkText: {
    fontSize: 14,
  },
}));

const INITIAL_PAGE_LIMIT = 10;

const TransactionList = ({ history, match }) => {
  const classes = useStyles();
  const { web3 } = React.useContext(GlobalContext);
  const [info, setInfo] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [isInvalid, setIsInvalid] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const contractInput = useInput("");
  const [startDate, setStartDate] = React.useState(null);

  const [delay, setDelay] = React.useState(new Date().getTime());

  const [rows, setRows] = React.useState(null);
  const [columns, setColumns] = React.useState(null);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const getData = async () => {
    if (contractInput.value.length !== 42) {
      return enqueueSnackbar(t("컨트랙트 주소는 42글자여야합니다."), { variant: "error" });
    }
    // const now = new Date().getTime();
    // if (now - delay < 10000) {
    //   return enqueueSnackbar(parseInt((now - delay) / 10000) + "초 뒤에 다시 시도하세요.", { variant: "error" });
    // }
    if (!startDate) {
      return enqueueSnackbar(t("날짜를 입력해주세요."), { variant: "error" });
    }

    const body = { contractAddress: contractInput.value, startDate: startDate };
    setIsLoading(true);
    const { data } = await axios.post(`${process.env.REACT_APP_HOST}/api/v1/getdata`, body);
    const keys = data.rows.length > 0 ? Object.keys(data.rows[0]) : [];
    if (keys.length === 0) {
      setIsLoading(false);
      return enqueueSnackbar(t("해당 날짜 데이터가 없습니다."), { variant: "error" });
    }
    const formatedRows = data.rows.map((item) => {
      const values = {};
      keys.map((key) => {
        let value = null;
        value = item[key];
        return (values[key] = value);
      });

      return values;
    });
    setRows(formatedRows);

    const newColumns = keys.map((item) => {
      const columnsObject = {
        field: item,
        label: item,
      };
      return columnsObject;
    });

    setColumns(newColumns);

    setDelay(new Date().getTime());
    setIsLoading(false);
  };

  return (
    <Page title="Transaction" className={classes.root}>
      <Container className={classes.container} maxWidth={"lg"}>
        <Card sx={{ p: 2 }}>
          {/* <Typography sx={{ mb: 2 }}>입력한 블록넘버 기준 최대 5000개까지만 조회됩니다.</Typography> */}
          <Box sx={{ maxWidth: 400, display: "flex", flexDirection: "column" }}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                label="날짜"
                value={startDate}
                onChange={(value) => {
                  setStartDate(moment(value).format("YYYY-MM-DD"));
                }}
                inputFormat={"yyyy-MM-dd"}
                mask={"____-__-__"}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <Input
              style={{ marginTop: 10 }}
              placeholder="컨트랙트 주소"
              value={contractInput.value}
              onChange={contractInput.onChange}
            />

            <Button disabled={isLoading} loading={isLoading} onClick={getData} variant="contained" sx={{ mt: 1 }}>
              데이터 조회
            </Button>
            <ExcelFile
              // Download_20230212_0xbdf7379Ef4128720E1ab4B4b9ceDBF81058A2cC6
              filename={"Download_" + moment(startDate).format("YYYYMMDD") + "_" + contractInput.value}
              element={
                <Button
                  onClick={() => {
                    setRows(null);
                    setColumns(null);
                  }}
                  variant="contained"
                  sx={{ mt: 1, width: "100%" }}
                >
                  다운
                </Button>
              }
            >
              {rows && (
                <ExcelSheet data={rows} name={"_"}>
                  {columns &&
                    columns.map((item, index) => {
                      return <ExcelColumn key={"columns" + index} label={item.label} value={item.field} />;
                    })}
                </ExcelSheet>
              )}
            </ExcelFile>
          </Box>
        </Card>
      </Container>
      {/* {isInvalid && <FullPage404 />} */}
      {/* {initRef.current === false && <FullPageLoader />} */}
    </Page>
  );
};

export default TransactionList;
